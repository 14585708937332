import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SystemErrorComponent } from './components/shared/system-error/system-error.component';
import { const_pages, const_routes } from './components/shared/const';
import { AuthGuard } from './guards/auth.guard';
import { LandingPageComponent } from './components/smart-resume/components/landing-page/landing-page.component';
import { DashboardDisputesToggleOffComponent } from './components/dashboard-disputes-toggle-off/dashboard-disputes-toggle-off.component';

export const routes: Routes = [
  { path:'', component: DashboardComponent},
  { path: const_routes.MYRECORDS, loadChildren: () => import('./components/my-records/my-records.module').then(m => m.MyRecordsModule) },
  { path: const_routes.DATACORRECTION, loadChildren: () => import('./components/student-disputes/student-disputes.module').then(m => m.StudentDisputesModule) },
  { path: const_routes.SELFSERVICE, loadChildren: () => import('./components/self-service/self-service.module').then(m => m.SelfServiceModule) },
  { path: const_routes.VERIFICATION, loadChildren: () => import('./components/verification-events/verfication-events.module').then(m => m.VerificationEventsModule) },
  { path: const_routes.SMART_RESUME, loadChildren: () => import('./components/smart-resume/smart-resume.module').then(m => m.SmartResumeModule) },
  { path: const_routes.PAGENOTFOUND, component: PageNotFoundComponent },
  { path: 'error', component: SystemErrorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {


 }
