import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { const_colors, const_pages, const_routes, page_title } from 'src/app/components/shared/const';
import { BlockingIndicatorService, OktaAuthService, SidebarEventEmitterService, SidebarService, TimeoutService } from 'student-ui-sso-integration-lib';
import { SidebarConfig, SidebarTitle, SidebarSubtitle } from 'student-ui-sso-integration-lib/lib/components/sidebar-nav/sidebar-interfaces';
import { TimeoutDialogueComponent } from '../timeout-dialogue/timeout-dialogue.component';
import { ApiService } from 'src/app/services/api.service';
import { routes } from 'src/app/app-routing.module';
import { DataService } from 'src/app/services/data.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app-root.component.html',
  styleUrls: ['./app-root.component.scss']
})
export class AppRootComponent implements OnInit, AfterViewInit {

  title = 'myhub-student-dispute-ui';
  isLoggedIn = false;
  useNSCHeader = false;
  useNSCFooter = false;
  isPageRefreshed = false;
  sysError = false;

  sidebarConfig!: SidebarConfig;
  sidebarTitles!: SidebarTitle[];

  dialogWarning: any;
  static SESSION_TIMEOUT: number = 10*60*1000; //inMilliSecs

  disputeFeatureToggle!: any;
  smartResumeFeatureToggle!: any;
  hasFeatureToggles!: boolean;

  currentUrl:any;
  displayServiceOutage!:any;
  serviceOutageMessage!:any;


  constructor(private sidebarService: SidebarEventEmitterService,
    private router: Router,
    private sidenavService: SidebarService,
    private timeoutService: TimeoutService,
    private dialog: MatDialog,
    private authService: OktaAuthService,
    private blocking: BlockingIndicatorService,
    private apiService: ApiService,
    private dataService: DataService,
    private titleService: Title) { }

  ngAfterViewInit(): void {
    this.onSidebarClick();
    this.onPageChange();
   }

  ngOnInit(): void {
    this.router.events.pipe(
      filter((event:any) => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.currentUrl = event.url;
    });
    this.startAppWithTimer();
    //this.setSidebarConfig();
    this.useNSCHeader = true;//environment.USE_NSC_HEADER;
    this.useNSCFooter = true;//environment.USE_NSC_FOOTER;
  }

  setSidebarConfig() {
    // Sidebar Icons and Titles
    this.sidebarTitles = [
      { text:'  Dashboard ', id: const_pages.DASHBOARD, icon:'assets/dashboard.svg',
        activeIcon: {'stroke':const_colors.MYHUB_COLOR},  inactiveIcon: {'stroke':const_colors.COLOR_WHITE}},
      { text:'  My Records ', id: const_pages.MYRECORDS, icon:'assets/my-records.svg',
        activeIcon: {'stroke':const_colors.MYHUB_COLOR},  inactiveIcon: {'stroke':const_colors.COLOR_WHITE}},
      { text:'  Self Service ', id: const_pages.SELFSERVICE, icon:'assets/self-service.svg',
        activeIcon: {'stroke':const_colors.MYHUB_COLOR},  inactiveIcon: {'stroke':const_colors.COLOR_WHITE}},
      { text:'  Verification Events ', id: const_pages.VERIFICATION, icon:'assets/verification-events.svg',
        activeIcon: {'stroke':const_colors.MYHUB_COLOR},  inactiveIcon: {'stroke':const_colors.COLOR_WHITE}}
    ];

    if(this.disputeFeatureToggle?.active) {
      this.sidebarTitles.push(
      { text:'  Data Correction ', id: const_pages.DATACORRECTION, icon:'assets/data-correction.svg',
        activeIcon: {'stroke':const_colors.MYHUB_COLOR},  inactiveIcon: {'stroke':const_colors.COLOR_WHITE}}
      );
    }

    this.sidebarConfig = { titles: this.sidebarTitles, color: const_colors.MYHUB_COLOR };

  }

  getFeatureToggles() {
    //get feature toggles
    this.sysError = false;
    this.hasFeatureToggles = false;
    this.apiService.getFeatureToggles().subscribe(response => {
      if(response.status === 200) {
        for(let i=0; i<response.body.length; i++) {
          if(response.body[i].featureName === 'Disputes') {
            this.disputeFeatureToggle = response.body[i];
          }
          if(response.body[i].featureName === 'Smart Resume') {
            this.smartResumeFeatureToggle = response.body[i];
            this.dataService.smartResumeFeatureToggle.next(this.smartResumeFeatureToggle.active);
          }

          if(response.body[i].featureName === 'ServiceOutage') {
            this.displayServiceOutage = response.body[i].active;
            this.serviceOutageMessage = response.body[i].systemMessage;
          }
        }
        //remove dispute route when toggle is false
        let modifiedRoutes = routes;
        if(!this.disputeFeatureToggle.active || !this.smartResumeFeatureToggle.active) {
          this.dataService.smartResumeFeatureToggle.next(false);
          // let dashboardRouteIndex = modifiedRoutes.findIndex(route => {
          //   return route.path === '';
          // });
          // modifiedRoutes[dashboardRouteIndex] = { path:'', component: DashboardDisputesToggleOffComponent, canActivate: [AuthGuard] }
        }
        if(!this.disputeFeatureToggle.active) {
          modifiedRoutes = routes.filter(route => {
          return route.path !== const_routes.DATACORRECTION
          });
        }
        this.router.resetConfig(modifiedRoutes);
        console.log("filteredRoutes ",modifiedRoutes);
        this.setSidebarConfig();
        this.hasFeatureToggles = true;
      } else {
        this.sysError = true;
        console.log("no response from feature toggle api. status :",response.status);
        this.router.resetConfig([]);
      }
    },
    (error) => {
      console.log("error from get features api",error);
    });
  }

  onSidebarClick() {
    //Routing pages when sidebar icon click event triggered
    this.sidebarService.getSidebarEvents().subscribe((res) => {
      console.log('nav click res: ',res);
      switch(res.title) {
        case const_pages.DASHBOARD :
          this.onSidenavIconClick('');
          break;
        case  const_pages.MYRECORDS :
          this.onSidenavIconClick(const_routes.MYRECORDS);
          break;
        case  const_pages.SELFSERVICE :
          this.onSidenavIconClick(const_routes.SELFSERVICE);
          break;
        case const_pages.VERIFICATION :
          this.onSidenavIconClick(const_routes.VERIFICATION);
          break;
        case  const_pages.DATACORRECTION :
          if(this.disputeFeatureToggle?.active) {
            this.onSidenavIconClick(const_routes.DATACORRECTION);
          }
          break;
      }
    });

  }

  onPageChange() {
    //Setting sidebar icon on route change
    this.router.events.subscribe((event:any) => {
      if(event instanceof NavigationStart) {
        console.log('route start : ',event.url);

        this.blocking.open();
        if(event.url === '/') {
          this.blocking.open();
          this.sidenavService.activateTitle(const_pages.DASHBOARD);
          this.titleService.setTitle(page_title.DASHBOARD);
        } else if(event.url.includes('myrecords')) {
          this.sidenavService.activateTitle(const_pages.MYRECORDS);
          this.titleService.setTitle(page_title.MYRECORDS);
        } else if(event.url.includes('selfservice')) {
          this.sidenavService.activateTitle(const_pages.SELFSERVICE);
          this.titleService.setTitle(page_title.SELFSERVICE);
        } else if(event.url.includes('verification')) {
          this.sidenavService.activateTitle(const_pages.VERIFICATION);
          this.titleService.setTitle(page_title.VERIFICATION);
        }
        else if(this.disputeFeatureToggle?.active && event.url.includes('datacorrection')) {
          this.sidenavService.activateTitle(const_pages.DATACORRECTION);
        }
      }

      if(event instanceof NavigationEnd) {
        console.log('route end : ',event.url);
        if(event.urlAfterRedirects.includes(event.url)){
          this.isPageRefreshed = true;
        } else {
          this.isPageRefreshed = false;
        }
        this.blocking.close();
      }

      if(event instanceof NavigationError) {
        this.router.navigateByUrl(const_routes.PAGENOTFOUND);
      }
    });
  }

  onSidenavIconClick(route:string) {
    this.router.navigateByUrl(route);
    document.getElementById('collapser')?.click();
    console.log('click fun completed.');

  }

  startAppWithTimer() {
  /* Changes for Session Timeout */
  this.timeoutService.durationWarning = AppRootComponent.SESSION_TIMEOUT;
  this.timeoutService.startWarningCountdown();
  this.authService.loginChanged.subscribe(loggedIn => {
    this.isLoggedIn = loggedIn;
    if(this.isLoggedIn) {
      this.getFeatureToggles();
    } else {
      console.log("user does not log in");
    }
  });
  this.timeoutService.showWarning.subscribe((showDialog: boolean) => {
    if (showDialog) {
      this.displayWarning();
    } else if (this.dialogWarning) {
      this.dialogWarning.close();
    }
  });
  this.timeoutService.deactiveWarning.subscribe((endSession: boolean) => {
    if (endSession) {
      this.authService.logout();
    }
  });
  }

  displayWarning(): void {
    if (this.dialogWarning) {
      this.dialogWarning.close();
    }
    // show the new warning dialog;
    this.dialogWarning = this.dialog.open(TimeoutDialogueComponent, {
      disableClose: true,
      width: '480px'
    });
  }

  @HostListener('document:keyup', [])
  @HostListener('document:click', [])
  @HostListener('document:wheel', [])
  @HostListener('document:touchmove', [])
  @HostListener('scroll', [])
  resetTimer() {
    this.timeoutService.startWarningCountdown();
  }

  @HostListener('window:beforeunload', [])
  onAppUnload() {
    if(this.isPageRefreshed) {
      sessionStorage.setItem('previousUrl', this.router.url);
      console.log("prevurl",this.router.url);

    }
  }

}
