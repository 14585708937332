<div class="nsc-app-root" [hidden]="!isLoggedIn">
    <!-- Routed Feature -->
    <div *ngIf="useNSCHeader" class="w-100">
      <lib-header headerValue="Myhub" rootUrl ="/education" appColor="#742C85"></lib-header>
    </div>

    <div class="d-flex flex-row flex-grow-1">
      <div class="side-nav">
        <!-- Sidebar from ui lib -->
        <lib-sidebar-nav *ngIf="sidebarConfig" [config]="sidebarConfig" style="min-height: 100%;"></lib-sidebar-nav>
      </div>
      <div class="w-100 flex-grow-1">
        <!-- Service Outage Code Start -->
        <div class="w-100 pt-4" *ngIf="displayServiceOutage" 
        [ngStyle]="currentUrl === '/' ? {background:'#FFF'} : {background:'#F0F0F0'}">
          <div class="system-alert-container">
            <img src="assets/alert-square.svg" alt="alert-svg">
            <div>
              <h4>Temporary Service Outage</h4>
              <p>{{serviceOutageMessage}}</p>
              <!-- <p>Enrollment Certificate download functionality and Transcript Ordering will be unavailable on Saturday, March 29 between 7:00AM to 11:00AM EST.</p> -->
            </div>
          </div>
        </div>
        <!-- Service Outage Code End -->
        <router-outlet></router-outlet>
      </div>
      <div class="nodata-container" *ngIf="sysError">
        <app-system-error idx="appRoot"></app-system-error>
      </div>
    </div>

    <!--Footer-->
    <div class="w-100"><lib-footer></lib-footer></div>
</div>

