import { AfterViewInit, Component, ContentChildren, ElementRef, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { NscStundentData, Organization, StudentCredentials } from 'src/app/classes/NSCStudentData';
import { DataService } from 'src/app/services/data.service';
import { ApiService } from 'src/app/services/api.service';
import { BlockingIndicatorService } from 'student-ui-sso-integration-lib';
import { forkJoin, of, throwError } from 'rxjs';
import { CredentialsService } from 'src/app/services/credentials.service';
import { MatDialog } from '@angular/material/dialog';
import { ScholarshipDialogueComponent } from '../scholarship-dialogue/scholarship-dialogue.component';
import { OktaAuthService } from 'student-ui-sso-integration-lib';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { SchoolProfile } from 'src/app/classes/SchoolProfile';
import { UnofficialtsService } from 'src/app/services/unofficialts.service';
import { UnofficialTranscriptView, UnofficialTSCourse, UnofficialTSSession } from 'src/app/classes/UnofficialTranscriptView';
import { AcademicRecord, AcademicSession, CollegeTranscript, Course } from '../../classes/CollegeTranscript';
import { const_routes } from '../shared/const';
import { routes } from 'src/app/app-routing.module';
import { Observable } from 'rxjs';
import { StudentDispService } from 'src/app/services/student-disp.service';
import { CredentialResponse } from 'src/app/classes/CredentialResponse';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  data!: NscStundentData;
  credentialData = {} as StudentCredentials;
  enrollmentLogos!: string[] | SafeResourceUrl[];
  credentialLogos!: string[] | SafeResourceUrl[];
  degreeLogos!: string[] | SafeResourceUrl[];
  hasEnrollments!: boolean;
  hasDegrees!: boolean;
  hasCredentials!: boolean;
  errorFromApis!: any;
  credUrls: any;
  isDataLoaded!: boolean;
  userFirstName!: string;
  logoCounter:number = 0;
  logosLoaded!: boolean;
  enterPopup: boolean = false;

  hasEnrollmentError: boolean = false;
  hasDegreeError: boolean = false;
  hasCredentialError: boolean = false;
  smartResumeFeatureToggle!: boolean;

  @ContentChildren('pagenotfound')
  pagenotfound!: ElementRef;

  constructor(private apiService: ApiService, private blocking: BlockingIndicatorService,
    private dataService: DataService,
    private credService: CredentialsService,
    private disputeService: StudentDispService,
    private dialog: MatDialog,
    private authService: OktaAuthService,
    private router: Router) { }

  ngOnInit(): void {
    this.dataService.smartResumeFeatureToggle.subscribe(
      flag => this.smartResumeFeatureToggle = flag
    );
    if (!this.dataService.isDataLoaded) {
      console.log('loading data');
      //console.time('subapis')
      this.subscribeApis();
      //console.timeEnd('subapis')
    } else {
      this.hasEnrollmentError=this.dataService.hasEnrollmentError;
      this.hasDegreeError=this.dataService.hasDegreeError;
      this.hasCredentialError=this.dataService.hasCredentialError;
      this.loadLogos();
    }

    if (this.authService.isLoggedIn()) {
      this.userFirstName = this.authService.getUserFirstName();
    };

  }

  subscribeApis() {
    setTimeout(() => {
      this.blocking.open();
    }, 0);
    try {
      forkJoin([
        this.apiService.getNSCStudentEnrollments().pipe(catchError(err => of(err))),
        this.apiService.getNSCStudentDegrees().pipe(catchError(err => of(err))),
        this.credService.getWalletCredentials().pipe(catchError(err => of(err)))
      ]).subscribe(([enrollmentRes, degreeRes, credentialRes]: any) => {
        this.hasEnrollments = this.hasDegrees = this.hasCredentials = false;
        console.log(enrollmentRes, degreeRes, credentialRes, enrollmentRes.error, degreeRes.error, credentialRes.error);

        if (!degreeRes.error) {
          //degrees data
          this.data = degreeRes;
          //this.data = this.dataService.getMockNew();
          if (this.data && this.data.studentOrgs) {
            //console.time('logos');
            this.dataService.saveStudentOrgs(this.data.studentOrgs);
            this.dataService.saveOrgsEnrollmentsAndDegrees(this.data.studentOrgs);
            //console.timeEnd('logos');
          }

          if (this.data.degrees?.studentDegrees?.length) {
            //console.time('degrees');
            this.dataService.saveDegrees(this.data.degrees);
            this.dataService.hasDegreeError=false;
            //console.timeEnd('degrees');
          } else {
            this.hasDegrees = false;
          }
        } else {
          this.hasDegreeError = true;
          this.dataService.hasDegreeError=true;
          console.log("degreeRes", degreeRes)
          console.log("degreeRes.error", degreeRes.error)
        }

        if (!enrollmentRes.error) {
          //enrollments data
          this.data = enrollmentRes;
          if (this.data && this.data.studentOrgs && !this.dataService.studentData?.studentOrgs) {
            //console.time('logos');
            this.dataService.saveStudentOrgs(this.data.studentOrgs);
            //console.timeEnd('logos');
          }

          if (this.data && this.data.studentOrgs) {
            //console.time('logos');
            this.dataService.saveOrgsEnrollmentsAndDegrees(this.data.studentOrgs);
            //console.timeEnd('logos');
          }
          //this.data = this.dataService.getMockNew();
          if (this.data.enrollments?.studentEnrollments?.length) {
            //console.time('enr');
            this.dataService.saveEnrollments(this.data.enrollments);
            this.dataService.hasEnrollmentError=false;
            //console.timeEnd('enr');
          } else {
            this.hasEnrollments = false;
          }
        } else {
          this.hasEnrollmentError = true;
          this.dataService.hasEnrollmentError=true;
          console.log("enrollmentRes", enrollmentRes)
          console.log("enrollmentRes.error", enrollmentRes.error)
        }

        if (!credentialRes.error) {
          //credentials data
          console.log("before credentials:", credentialRes);
          if (credentialRes?.credentials?.length) {
            console.log("In Credentials flow");
            this.hasCredentials = true;
            this.dataService.hasCredentialError=false;
            this.data = this.dataService.get();
            this.data.badgeCredentials.credentials = credentialRes.credentials;
            console.log(this.data.badgeCredentials);
          } else {
            console.log("has No credentials", credentialRes);
            this.hasCredentials = false;
            if (this.data?.badgeCredentials) {
              this.data.badgeCredentials = credentialRes;
            } else {
              //this.hasCredentialError = true;
            }
          }
          if (this.data?.badgeCredentials) {
            //console.time('cred');
            this.dataService.saveCredentials(this.data.badgeCredentials);
            //console.timeEnd('cred');
          }
        } else {
          this.hasCredentialError = true;
          this.dataService.hasCredentialError=true;
          console.log("credentialRes", credentialRes)
          console.log("credentialRes.error", credentialRes.error)
        }

        //get logos
        //console.time('loadlogos');
        this.loadLogos()
        //console.timeEnd('loadlogos');
        if(this.logosLoaded) {
          this.blocking.close();
        }
        this.loadCourses();
        this.loadWallet();
      },
        (error) => {
          console.error('fork join error', error);
          this.blocking.close();
        },
        () => {
          this.loadSchoolProfiles();
          this.redirectOnPageRefresh();
          this.blocking.close();
          this.isDataLoaded = true;
        });
    } catch (error) {
      console.log("exception occured")
      this.blocking.close();
    }
  }

  loadLogos() {
    //console.time('logoEnr');
    this.enrollmentLogos = this.dataService.getEnrollmentImagesForDisplay();
    //console.timeEnd('logoEnr');
    //console.time('logoCred');
    this.credentialLogos = this.dataService.getCredentialImagesForDisplay();
    //console.timeEnd('logoCred');
    //console.time('logoDeg');
    this.degreeLogos = this.dataService.getDegreeImagesForDisplay();
    //console.timeEnd('logoDeg');
    if (this.enrollmentLogos?.length > 0) {
      this.hasEnrollments = true;
    }
    if (this.degreeLogos?.length > 0) {
      this.hasDegrees = true;
    }

    if (!this.hasCredentialError) {
      if (!this.dataService.get().badgeCredentials?.credlyRedirectUrl) {
        this.loadCredlyRedirectUrl();
      }

      if (!this.dataService.get().badgeCredentials.badgrAccountExists && !this.dataService.get().badgeCredentials.credlyAccountExists) {
        console.log("No badgeCredentials");
        this.hasCredentials = false;
        this.credUrls = {
          credlyUrl: this.dataService.get().badgeCredentials.credlyRedirectUrl,
          badgrUrl: this.dataService.get().badgeCredentials.badgrRedirectUrl
        }
      } else {
        this.hasCredentials = true;
      }
    }

    this.dataService.isDataLoaded = true;
    this.isDataLoaded = true;
  }

  loadCourses(): void {
    this.apiService.getNSCStudentCourses().subscribe(
      (response: any) => {
        this.data = response;
        this.dataService.hasCourseError=false;
        if (this.data.courses) {
          this.dataService.saveCourses(this.data.courses);
        }
      },
      (error) => {
        console.log('loading courses failed', error);
        this.dataService.hasCourseError=true;
      }
    );
  }

  loadWallet(): void {
    this.apiService.getWalletProfile().subscribe(
      (response: any) => {
        console.log("wallet response:",response);
        if(response) {
          this.loadUnofficialTSOrgs();
          if(!this.hasDegreeError) {
            this.loadWalletNscCredentails();
          }
        }
      },
      (error) => {
        console.log('loading wallet  failed', error);
      }
    );
  }

  loadUnofficialTSOrgs() {
    console.log("in loadUnofficialTranscripts");

    let studentProfileId: string;
    this.authService.getUserProfile().subscribe((res:any) => {
      studentProfileId = res.studentProfileID;
      console.log("studentProfileId",studentProfileId);

      if(studentProfileId) {
        this.apiService.getUnofficialTranscripts(studentProfileId).subscribe(
          (res:SchoolProfile[]) => {
          console.log("unofficial res",res);
          this.dataService.saveUnofficialTSOrgs(res);
          if(this.dataService.studentData?.unofficialTSOrgs.length > 0) {
            this.dataService.hasUnofficialTS.next(true);
          }
          },
          (error) => {
            console.log("getUnofficialTranscripts failed",error);
            this.dataService.hasLoadedUnofficialTS.next(true);
          },
          () => {
            this.dataService.hasLoadedUnofficialTS.next(true);
          });
      }
    })
  }

  loadCredlyRedirectUrl(): void {
    this.credService.refreshCredlyBadges().subscribe(
      (response: any) => {
        this.data = this.dataService.get();
        this.data.badgeCredentials.credlyRedirectUrl = response.redirectUrl;
        this.dataService.saveCredentials(this.data.badgeCredentials);
        console.log(this.dataService.get().badgeCredentials);
      },
      (error) => {
        console.log('loadCredlyRedirectUrl failed', error);
      }
    );
  }

  loadSchoolProfiles() {
    let opeIdList: string[] = [];
    this.dataService.get().studentOrgs.organizations.forEach(
      (org:Organization) => {
        opeIdList.push(org.schoolCode + org.schoolBranch);
      }
    );

    this.apiService.getProfileSchoolsMap(opeIdList)
      .subscribe(dataMap => {
        console.log("schools profiles list",dataMap);
        this.dataService.saveSchoolProfiles(dataMap);
        console.log("schools frm dataservice",this.dataService.schoolProfilesMap);
      },
      (error) => {
        console.error("cannot get school profiles",error);
      },
      () => {
        this.redirectOnPageRefresh();
        this.blocking.close();
        console.log("blocking close db");
      });
  }

  scholarshipDialog() {
    this.dialog.open(ScholarshipDialogueComponent, {
      disableClose: true,
      width: 'auto'
    });
  }

  onEnterScholarshipDialog(event: any) {
    this.enterPopup = !this.enterPopup;
    if(this.enterPopup) {
      this.scholarshipDialog();
    }
  }

  onCardClick(hasData: boolean, routeUrl: string) {
    if (hasData) {
      this.blocking.open();
      console.log('card clicked : ', routeUrl);
      this.router.navigateByUrl(routeUrl);

    }
  }

  onCardClickNoCondition(routeUrl: string) {
    this.onCardClick(true, routeUrl);
  }

  onLoaded() {
    this.logoCounter++;
    if(this.logoCounter > this.credentialLogos.length) {
      console.log('logos loaded');

      this.blocking.close();
      this.logosLoaded = true;
    }
  }

  redirectOnPageRefresh() {
    const url = sessionStorage.getItem('previousUrl');
    console.log("previousUrl: ",url);
    let disputeRoute = routes.find(route => {return route.path === const_routes.DATACORRECTION});

    if(url?.includes(const_routes.DATACORRECTION) && !disputeRoute) {
      //Do nothing
    } else if(url) {
      this.router.navigateByUrl(url);
      sessionStorage.removeItem('previousUrl');
    }
  }

  loadWalletNscCredentails(){
    this.disputeService.getCredentials().subscribe(
      (response: CredentialResponse) => {
        this.dataService.saveNscCredentails(response.credentials);
      },
      (error:any) => {
        console.log('loadWalletNscCredentails  failed', error);
      }
    );
  }

}
